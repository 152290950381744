
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Custom pallete

$datium-primary-palette: (
  50: #e3f2fb,
  100: #bcdff5,
  200: #92cbf0,
  300: #6ab7e9,
  400: #4aa7e6,
  500: #2d99e3,
  600: #248bd6,
  700: #197ac3,
  800: #1169b2,
  900: #004c93,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$datium-accent-palette: (
  50: #fffce7,
  100: #fff7c4,
  200: #fff29d,
  300: #ffed77,
  400: #fde759,
  500: #fbe23c,
  600: #fcd33b,
  700: #f9bb34,
  800: #f6a32d,
  900: #f07b22,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$datium-warn-palette: (
  50: #feeaee,
  100: #fecbd2,
  200: #ed969a,
  300: #e36c72,
  400: #ed4850,
  500: #f23236,
  600: #e32735,
  700: #d11b2f,
  800: #c41128,
  900: #b5001c,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$instantval-primary: mat.define-palette($datium-primary-palette, 900, 800, 700);
$instantval-accent: mat.define-palette($datium-accent-palette, 900, 800, 700);

// The warn palette is optional (defaults to red).
$instantval-warn: mat.define-palette($datium-warn-palette, 400, 500, 600);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$instantval-theme: mat.define-light-theme((
  color: (
    primary: $instantval-primary,
    accent: $instantval-accent,
    warn: $instantval-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($instantval-theme);

/* You can add global styles to this file, and also import other style files */

@import 'assets/styles/common.scss';
