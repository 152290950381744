@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;500;600;700&display=swap');

@import 'reboot';
@import 'grid';
@import 'variables';

:root {
    font-size: 14px !important;
}

body {
    font-family: $baseFont !important;
    font-size: 1rem !important;
    background: $bgColor;
    color: $fontBaseColor;
}


img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $baseFont !important;
}

h1 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
}

h2 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

h4 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

h5 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.container-fluid {
    padding-right: 30px;
    padding-left: 30px;
}

.text-center {
    text-align: center;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

.position-relative {
    position: relative;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.material-symbols-outlined {
    user-select: none;
}

.text-primary {
    color: $primaryColor;
}

.text-success {
    color: $green;
}

.text-warning{
    color: #fd7e14;
}

.text-error{
    color: $red;
}

.text-muted {
    color: $darkGray;
}

.wpt-tag {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 13px;
    text-transform: uppercase;
    color: $white;    

    &.error {
        background: $red;
    }

    &.success {
        background: $green;
    }
}

.my_account_wrapper{
    .wpt-tag{
        min-width: 95px;
        display: flex;
        height: 21px;
        align-items: center;
        justify-content: center;
    }
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.white-bg-section,
.mat-mdc-card {
    padding: 1.5rem;
    background: $white;
    border-radius: 6px;
    @extend .shadow-lg;
}

.mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: #{$primaryColor};
    --mdc-switch-selected-handle-color: #{$primaryColor};
    --mdc-switch-selected-hover-state-layer-color: #{$primaryColor};
    --mdc-switch-selected-pressed-state-layer-color: #{$primaryColor};
}

// Page wrapper style

.di-page-wrapper {
    padding: 30px 0;
}

.mat-flat-button {
    text-transform: uppercase;
}

.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

app-master {
    display: block;
    padding: 30px 0;
}

.bg-white {
    background: #ffffff;
}

.shadow {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

.shadow-sm {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

.shadow-md {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

.shadow-lg {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

// custom tabs

.custom-page-tabs {
    margin: 15px 0 30px 0;

    .mat-mdc-outlined-button {
        margin: 0 10px 0 0;

        &.active {
            background: $primaryColor;
            border-color: $primaryColor;
            color: $white;
        }
    }
}

.date-range-dropdown {
    label {
        display: block;
        margin-bottom: 9px;
    }

    .range-selector {
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #D9D9D9;
        padding: 0.7rem 0.8rem;
        border-radius: 4px;
        position: relative;
        height: 35px;
        display: flex;
        align-items: center;

        i {
            display: inline-block;
            margin: 0 5px;
        }

        .mat-select-arrow {
            position: absolute;
            right: 8px;
            top: 16px !important;
        }
    }
}

.custom-range-dropdown {
    padding: 20px;
    display: flex;
    flex-direction: column;

    .mat-form-field {
        width: 100%;
        min-width: 180px;
        max-width: 180px;

        .mat-form-field-wrapper {
            margin-bottom: 0;
        }
    }
}

.no-data-found {
    padding: 4rem 0;
    text-align: center;

    p {
        font-size: 20px;
        color: #d97575;
        margin: 10px 0 0 0;
        font-weight: 600;
    }
}


// buttons style

.mdc-button {
    text-transform: uppercase;
    font-size: .825rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    height: 35px !important;
    text-decoration: none;
    min-width: max-content !important;

    .mdc-button__label {
        display: flex;
        align-items: center;

        .material-symbols-outlined {
            font-size: 18px;
            display: inline-block;
            margin: 0 8px 0 0;
        }
    }

    &.btn-block {
        width: 100% !important;
        justify-content: center;
        display: flex;
    }

    &.mat-mdc-outlined-button {
        background-color: #ffffff;

        &.mat-primary {
            --mdc-outlined-button-outline-color: #004C93;
        }

        &.mat-accent {
            --mdc-outlined-button-outline-color: #F07B22;
        }

        &.mat-warn {
            --mdc-outlined-button-outline-color: #ED4850;
        }
    }

    &.mat-mdc-unelevated-button {
        &.mat-accent {
            --mdc-filled-button-label-text-color: #fff;
        }
    }

    &.small {
        min-width: auto;
    }

    &.large {
        height: 42px !important;
        font-weight: 700 !important;
        font-size: 1rem !important;
    }
}

// company page style

.company_sub_row {
    padding: 1.5rem;
    background: $white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
    margin: 1.5rem 0;

    .company_info {
        .company_thumb {
            height: 80px;
            width: 80px;
            min-width: 80px;
            border-radius: 100%;
            overflow: hidden;
            padding: 0;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg,
            img {
                min-width: 80px;
                width: 80px;
                height: 80px;
            }
        }

        .company_name {
            .author {
                .single-info {
                    display: flex;
                    align-items: flex-start;
                    margin: 0 0 10px 0;

                    span {
                        display: inline-block;
                        position: relative;
                        width: 70px;
                        min-width:70px;
                        margin: 0 15px 0 0;

                        &:after {
                            content: ":";
                            position: absolute;
                            right: 0px;
                        }
                    }

                    div{
                        word-break: break-word;
                    }

                }
            }
        }
    }
}

// table style

.custom-table {
    width: 100%;
    box-shadow: 0px 0px 12px rgba($black, 0.1) !important;
    border-radius: 6px !important;
    font-size: 14px;
    overflow: hidden;

    tr.mat-mdc-header-row,
    tr.mat-mdc-footer-row,
    tr.mat-mdc-row {
        width: 100%;
    }

    tr.mat-row.loading {
        position: relative;

        &:after {
            content: "Please wait...";
            position: absolute;
            z-index: 10;
            background: #eee;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    th {
        padding: 0 10px !important;
        line-height: 1.1;
        font-weight: 600;
        font-size: 0.9rem;
    }

    td {
        padding: 10px !important;
        line-height: 1.1;
        min-width: max-content;

        .description {
            margin: 10px 0 0 0;
        }

        .product-details {
            padding: 10px 0;
        }

        .mdc-evolution-chip-set{
            .mdc-evolution-chip-set__chips{
                margin-left:0 !important;
            }
        }
    }
}

.asset-info-table{
    .mat-mdc-cell{
        min-width: 50px;
    }
    .mdc-data-table__cell{
        &.products{            
            word-break: break-all;
            min-width: 350px;
            max-width:550px;
            padding-top:5px;
            padding-bottom:5px;
        }
        &.date{ 
            min-width: 104px;
        }
    }
}

.table-actions {
    display: flex;
    align-items: center;

    a {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
}

// custom counter design

.custom-counter {
    border: 2px solid rgba($black, 0.1);
    display: flex;
    align-items: center;
    border-radius: 3px;
    width: 120px;
    margin: 0 auto;

    .btn-wrap {
        width: 100%;

        .btn {
            width: 100% !important;
        }
    }

    .input-group {
        .form-control {
            border: 0;
            padding: 0;
            min-width: auto;
            background: rgba($primaryColor, 0.1);
            -moz-appearance: textfield;
            border-radius: 0;
            height: 34px;
            width: 38px !important;
            outline: none !important;
            border: 0 !important;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .btn-wrap {
        .btn {
            padding: 0;
            height: 34px;
            width: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primaryColor;
            background: #fff;
            outline: none;
            border: 0;

            &:active,
            &:focus {
                outline: none;
                border: 0;
            }
        }
    }
}


.option_subscription {
    margin: 1rem 0 0 0;
    padding: 0;
    max-height: 330px;

    li {
        list-style: none;

        .child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .mat-mdc-radio-button {
                display: block !important;
                width: 100%;
                cursor: pointer;

                .mdc-label {
                    padding:0;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border-radius: 0.3rem;
                    span{
                        margin:0 0 0 10px;
                    }
                }

                &.mat-radio-checked {
                    .mat-radio-label {
                        background: lighten($primaryColor, 48%);
                    }
                }
            }

            span.price {
                position: absolute;
                right: 1rem;
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }

    &.payment_methods {

        li {
            position: relative;

            .payment_icons {
                order: 2;
            }

            .child {
                .mat-radio-button {
                    .mat-radio-label {
                        border-radius: 0;
                        background: transparent;
                        padding: 0 0;
                        border-radius: 0;
                    }
                }
            }

            .mat-radio-label-content {
                display: flex;
                align-items: center;

                .card-icon {
                    min-width: 50px;
                    width: 50px;
                    text-align: center;
                    margin: 0 0.5rem;

                    img {
                        max-height: 22px;
                    }
                }
            }

        }
    }

}

mat-chip-set,
mat-chip-list {
    .mat-mdc-chip {
        font-size: 12px;
        border-radius: 4px;
        height: 25px;

        &.primary,
        &.mat-primary {
            background: $primaryColor;
            margin: 0 4px 4px 0;

            .mat-mdc-chip-action-label {
                color: $white;
            }
        }

        &.grey {
            background-color: #e0e0e0;

            .mat-mdc-chip-action-label {
                color: #212121;
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;

    a {
        .material-symbols-outlined {
            font-size: 22px;
            height: 22px;
            width: 22px;
            color: #333;
        }
    }

    .btn-link {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 10px 0 0;
        color: $primaryColor;
        cursor: pointer;
        text-decoration: none;
        background-color: transparent;
        border: 0;
        outline: none;

        .material-symbols-outlined {
            margin: 0 4px 0 0;
            font-size: 20px;
            color: $primaryColor;
        }

        &:hover {
            text-decoration: none;
        }
    }

}

// filter bar

.subscription-listing {
    .btn-wrapper {
        button {
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-left: 8px;

            .material-symbols-outlined {
                margin: 0 5px 0 0;
                height: 20px;
                width: 20px;
                font-size: 20px;
            }
        }
    }
}

.filter-row {
    background: $white;
    padding: 1.5rem;
}

// radio selection

.di-radio-selection {
    .mat-mdc-radio-group {
        display: flex;
        flex-wrap: wrap;

        .mat-mdc-radio-button {
            margin: 0 10px 10px 0;

            .mdc-radio {
                position: absolute;
                opacity: 0;
                visibility: hidden;
            }

            .mdc-label {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                background-color: $white;
                border-radius: 4px;
                font-family: $baseFont;
                border: 1px solid $primaryColor;
                color: $primaryColor;
                font-weight: 600;
                transition: all 0.3s;
                cursor: pointer;

                &:hover {
                    background: $primaryColor;
                    color: $white;
                }
            }

            &.mat-mdc-radio-checked {
                .mdc-label {
                    background: $primaryColor;
                    color: $white;
                }
            }
        }
    }

    &.full-width {
        .mat-radio-group {
            flex-wrap: inherit;

            .mat-radio-button {
                width: 100%;

                &:last-child {
                    margin-right: 0;
                }

                .mat-radio-label {
                    justify-content: center;
                }
            }
        }
    }
}

// Input field styles

.custom-label {
    width: 100%;
    display: block;
    margin: 0 0 8px 0;
    font-size: 1rem;
}

.mat-mdc-form-field-error{
    line-height:1;
}

.mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 1 !important;
    width: 100%;
    margin: 0 0 25px 0;

    .mat-mdc-text-field-wrapper {
        height: 35px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background: #ffffff !important;

        .mat-mdc-form-field-input-control:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        &.mdc-text-field--invalid {
            border-color: $red;
        }

        // Remove input notches

        .mdc-notched-outline {

            .mdc-notched-outline__leading,
            .mdc-notched-outline__trailing {
                display: none !important;
            }

            .mdc-notched-outline__notch {
                border: 0 !important;
                padding: 0 !important;
            }
        }

        // Outline input style

        &.mdc-text-field--outlined {
            .mat-mdc-form-field-infix {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                min-height: 35px
            }
        }

        // Rewrite label styles

        .mat-mdc-floating-label {
            cursor: pointer;

            &.mdc-floating-label {
                top: 15px;
                left: 16px;

                &.mdc-floating-label--float-above {
                    left: 0;
                    --mat-mdc-form-field-label-transform: translateY(-38px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.9));
                }
            }
        }

        &.mdc-text-field--focused:not(.mdc-text-field--disabled) {
            .mdc-floating-label {
                color: rgba(0, 0, 0, 0.87);
            }
        }

        // Select fields style

        .mat-mdc-select-arrow {
            svg {
                display: none;
            }

            height:24px;
            width:24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.0625 0L8 0.9375L4 4.9375L0 0.9375L0.9375 0L4 3.0625L7.0625 0Z' fill='black'/%3E%3C/svg%3E") no-repeat center right;
            right:-2px;
        }

        .mat-mdc-select {
            .mat-mdc-select-arrow-wrapper {
                height: 30px;
            }
        }

        // Text input styles

        .mat-mdc-form-field-input-control,
        .mat-date-range-input-container {
            height: 30px !important;
        }

        // Datepicker icon

        .mat-datepicker-toggle {
            .mat-mdc-icon-button {
                height: 33px;
                width: 33px;
                padding: 0;

                svg {
                    height: 18px;
                    width: 18px;
                }
            }
        }

        .mat-mdc-form-field-icon-suffix{
            .mdc-icon-button{
                padding: 5px;
                height: 35px;
                width: 35px;
            }
        }

    }

    // Error and hint style

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
        .mat-mdc-form-field-error-wrapper {
            font-size: 80%;
        }
    }

    // Rego state field

    &.regis-field {
        position: relative;

        .mdc-text-field {
            padding-left: 60px;
            position: relative;
            z-index: 1;
        }

        .mat-mdc-form-field-subscript-wrapper {
            position: absolute;
            top: 5px;
            left: 6px;
            z-index: 5;
            width: max-content;

            .mat-mdc-form-field-hint {
                &:before {
                    display: none;
                }

                font-size: .9rem;
                background: #333;
                color: #fff;
                width: max-content;
                border-radius: 3px;
                padding: 5px 10px;
            }
        }
    }

    // small fields

    &.state {
        .mat-mdc-text-field-wrapper {
            &.mdc-text-field--outlined {
                .mat-mdc-form-field-infix {
                    width: 60px;
                }
            }
        }
    }

    &.plat-number {
        .mat-mdc-text-field-wrapper {
            &.mdc-text-field--outlined {
                .mat-mdc-form-field-infix {
                    width: 100px;
                }
            }
        }
    }

    &.country-code {
        .mat-mdc-form-field-icon-prefix {
            left: 7px;
            padding: 3px 8px 5px 8px;
            background: #eee;
            border-radius: 5px;
            bottom: 2px;
            z-index: 10;
            display: flex;
            height: 24px;
            align-items: center;
            margin: 0 15px 0 0;
        }
    }

}

.search-plate-wrapper {
    display: flex;

    .di-input-wrapper {
        margin: 0 10px 0 0;
    }
}

.mat-mdc-optgroup-label {
    min-height: 35px;

    .mdc-list-item__primary-text {
        display: block;
        width: 100%;
    }
}

// Steps styles

.di-steps-wrapper {
    margin: 30px 0 0 0;
}

.di-step-wrapper {
    background: $white;
    box-shadow: 0px 0px 16px rgba($black, 0.1);
    border-radius: 20px;
    padding: 30px;
    position: relative;
    min-height: 150px;
    height: 100%;

    &.outlined {
        border: 1px solid $primaryColor;
        overflow: hidden;
        box-shadow: none;
    }

    .step-heading {
        display: flex;
        align-items: center;
        font-size: 1.429rem;
        font-weight: 600;
        margin: 0 0 30px 0;

        .material-symbols-outlined {
            margin-right: 10px;
        }
    }

    .section-loader {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 0.8rem;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        min-height: 150px;

        .mat-mdc-progress-spinner {
            height: 32px !important;
            width: 32px !important;

            svg {
                height: 32px !important;
                width: 32px !important;
            }
        }
    }

    .bottom_link {
        a {
            color: $primaryColor;
        }
    }

    .car-result-box {
        padding: 25px;
        background: #f9f9f9;
        border-radius: 5px;
    }

    .di-action-btns {
        display: flex;
        justify-content: space-between;

        button {
            width: 47%;
        }
    }
}

.di-vehicle-selection {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 24px;
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }

    .di-vehicle-description {
        margin: 0 0 15px 0;

        h2 {
            font-size: 1.286rem;
            line-height: 1.4;
            font-weight: 600;
            margin: 0 0 5px 0;
            color: $primaryColor;
        }

        h3 {
            font-size: 1.143rem;
            font-weight: 600;
            margin: 0 0 10px 0;
            line-height: 1.2;
            color: $black;
        }

        p {
            font-size: 1.143rem;
            color: $black;
        }
    }

    .di-vehicle-stats {
        .stat {
            font-size: 1.143rem;
            margin: 0 0 4px 0;
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                min-width: 120px;
                position: relative;
                margin-right: 10px;
                color: rgba($black, 0.54);
            }
        }
    }

    &.loading-description {
        h2.animatingLayer {
            height: 25px;
            max-width: 240px;
            margin: 0 0 20px 0;
        }

        .di-vehicle-stats {
            .stat {
                width: 260px;
                margin: 0 0 10px 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .di-action {
            .animatingLayer {
                max-width: 150px;
                height: 35px;
                border-radius: 2px;
            }
        }
    }

    &.visible-items {
        background: $white;
    }
}

.di-selected-vehicle {
    height: 100%;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 10px;

    .di-vehicle-description {
        margin: 0 0 15px 0;

        h2 {
            font-size: 1.286rem;
            line-height: 1.4;
            font-weight: 600;
            margin: 0 0 5px 0;
            color: $primaryColor;
        }

        h3 {
            font-size: 1.143rem;
            font-weight: 600;
            margin: 0 0 10px 0;
            line-height: 1.2;
            color: $black;
        }

        p {
            font-size: 1.143rem;
            color: $black;
        }
    }

    .di-vehicle-stats {
        .stat {
            font-size: 1rem;
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
            border-bottom: 1px solid #CCCCCC;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                min-width: 120px;
                position: relative;
                margin-right: 10px;
                color: rgba($black, 0.54);
            }

            .value {
                text-align: right;
            }
        }
    }

    &.selection {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid rgba($black, 0.35);
    }

    &.result {
        background: #fff;
        padding: 30px;
        border-radius: 20px;
    }

    &.outline {
        border: 1px solid $primaryColor;
    }

    &.loading-description {
        .di-vehicle-description {
            h2 {
                min-height: 30px;
                max-width: 30%;
            }

            h3 {
                min-height: 30px;
                max-width: 70%;
            }

            p {
                min-height: 20px;
            }
        }

        .di-vehicle-stats {
            margin: 20px 0;

            .stat {
                min-height: 20px;
                border-bottom: 0;
                margin: 5px 0;
            }
        }

        .di-action {
            .animatingLayer {
                min-height: 35px;
            }
        }
    }
}

// Find my car selections

.di-selections {
    padding: 30px;
    font-size: 1.15rem;

    .listLabel {
        margin: 0 0 12px 0;
        display: flex;

        label {
            position: relative;
            min-width: 130px;
            margin-right: 10px;
            opacity: 0.5;

            &:after {
                content: ':';
                position: absolute;
                right: 0;
            }
        }

        .label-value {}
    }

    .change-step {
        cursor: pointer;
        height: 16px;
        width: 16px;
        margin-left: 10px;

        .material-symbols-outlined {
            font-size: 16px;
            height: 16px;
            width: 16px;
            color: #555555;
            position: relative;
            top: 2px;
        }

        &.disabled {
            opacity: 0.6;
            cursor: default;
        }
    }

    h3 {
        font-size: 1.286rem;
        font-weight: 600;
        color: $primaryColor;
        display: flex;
        align-items: center;
        margin: 0;

        .change-step {
            .material-symbols-outlined {
                top: -3px;
            }
        }
    }

    button {
        margin: 0 0 10px 0;

        &.mat-button-disabled {
            border-color: #ccc !important;
        }

        &:hover {
            background: #fff !important;
        }
    }

}



@media screen and (max-width: $lg) {
    .di-selections {
        padding-left: 0;
    }
}

@media screen and (max-width: $md) {
    .di-selections {
        padding: 0 10px 2px 10px;
        margin-bottom: 20px;
        box-shadow: 0 0 16px rgba($black, 0.1);
        border-radius: 5px;
        background: $white;

        .selection-heading-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                padding: 0;
                height: auto;
                border: 0 !important;
                margin: 0;
                color: #333;
                line-height: initial;

                .mat-button-wrapper {
                    height: 15px;
                    padding: 0 !important;
                    margin: 0 !important;
                    width: 55px;
                }

                &.toggleFilters {
                    border-radius: 5px;
                    padding: 3px 8px;
                    background-color: #555555;
                    color: #fff;
                    font-size: 0.9rem;

                    &:active,
                    &:focus,
                    &:hover {
                        background: #555555 !important;
                        color: #fff;
                    }
                }
            }
        }

        .filters-tags {
            h3 {
                font-size: 1rem;
                color: #333;
                line-height: 1.6;
                font-weight: 400;
            }

            h3,
            p,
            .listLabel {
                font-size: 1rem;
                padding: 4px 8px;
                background-color: #F9F9F9;
                border-radius: 4px;
                display: inline-flex;
                margin: 0 8px 8px 0;
            }

            .listLabel {
                label {
                    display: none;
                }
            }

            .vehicle-selection-wrapper {
                padding: 0 !important;
                display: inline-flex;
                flex-wrap: wrap;
            }
        }
    }
}

.animatingLayer {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #efebf9;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #efebf9), color-stop(18%, #e2dbf4), color-stop(33%, #efebf9));
    background: -webkit-linear-gradient(left, #efebf9 8%, #e2dbf4 18%, #efebf9 33%);
    background: linear-gradient(to right, #efebf9 8%, #e2dbf4 18%, #efebf9 33%);
    -webkit-background-size: 800px 104px;
    background-size: 800px 104px;
    position: relative;
    height: 12px;
}

.loading-skeleton {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
        margin: 0 10px 10px 0;
        border-radius: 3px;
        width: 60px;
        height: 32px;
    }
}

.app-loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    mat-spinner,
    svg {
        width: 50px;
        height: 50px;
    }
}

// loader styles

app-loader {
    display: block;
    width: 100%;
}

.data_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
    flex-direction: column;

    .mat-mdc-progress-spinner {
        width: 50px !important;
        height: 50px !important;
        margin: 0 auto;
        margin-bottom: 15px;

        svg {
            width: 50px !important;
            height: 50px !important;
        }
    }
}

.loading-valuation {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .mat-mdc-progress-spinner,
    svg {
        width: 50px !important;
        height: 50px !important;
    }

    .mat-mdc-progress-spinner {
        margin: 0 0 15px 0;
    }
}

.toast-container {
    position: fixed;
    right: 30px;
    bottom: 30px;

    .toast-error {
        background: $red;
        color: $white;
        padding: 15px;
        border-radius: 5px;
        right: 20px;
        bottom: 20px;
    }

    .toast-success {
        background: $green;
        color: $white;
        padding: 15px;
        border-radius: 5px;
        right: 20px;
        bottom: 20px;
    }
}

// popup styles

.mat-mdc-dialog-container {
    position: relative;
    max-width: 700px !important;
    min-width:550px !important;
    width: 100% !important;
    background: #f8f8f8 !important;

    .mat-mdc-dialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;

        h2 {
            margin: 0;
        }

        &:before {
            height: 0;
            display: none;
        }

        .mat-mdc-icon-button {
            height: 32px;
            width: 32px;
            padding: 0;

            span {
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .mdc-dialog__content {
        max-height: 55vh !important;
        // overflow: inherit;
        font-family: $baseFont !important;
    }

    .mdc-dialog__actions {
        padding: 15px 24px;
    }

    .offer-steps {
        display: flex;
        align-items: center;
        line-height: 1;
        margin: 0 0 20px 0;

        svg {
            margin: 0 5px;
        }

        button {
            padding: 0;
            background: transparent;
            border: 0;
            font-size: 20px;
        }
    }

    .close-popup {
        text-decoration: none;
        position: absolute;
        right: 25px;
        top: 20px;
        padding: 0;
        background: transparent;
        border: 0;
    }

    .insta_api_key {
        .mat-form-field-infix {
            padding-right: 2rem !important;
        }
    }
}

.confirmation_popup {
    max-width: 450px;
    min-width:350px;
    width: 100%;
    margin: 0 auto !important;

    h2 {
        font-size: 1.6rem;
        margin: 1rem 0 1.5rem 0;
    }

    .confirm_btns {
        .btn {
            min-width: 120px;
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

div.mat-mdc-select-panel{
    padding:0 !important;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

.mat-tooltip {
    margin-top: 0 !important;
}

.buy-information{
    .mat-form-field-appearance-legacy{
      .mat-form-field-subscript-wrapper{
        margin-top: 1.8em !important;
      }
    }
    .mat-mdc-form-field-icon-suffix{
      background: #333;
      color: $white;
      font-weight:600;
      padding: 12px 10px !important;
      border-radius: 4px;
      bottom: 2px !important;
      right: 5px !important;    
    }
  }

// tabs design

.mat-mdc-tab-header {
    margin: 25px 0;
}

.mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
    padding: 0 0 0 16px !important;
}

@media only screen and (max-width: $xxl) and (min-width: $xl) {
    .container-xxl {
        padding: 0 30px;
    }
}

@media screen and (max-width: $lg) {

    .di-page-wrapper {
        padding: 20px 10px;
    }

    .di-step-wrapper {
        padding: 20px;

        &.outlined {
            margin: 0 0 20px 0;
            height: auto;
        }
    }

    .di-vehicle-selection {
        padding: 15px;

        .di-action {
            margin: 15px 0 0 0;

            button {
                min-width: auto;
            }
        }
    }

    .breif-car-box {
        background: #f5f5f5;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 15px;

        span.material-symbols-outlined {
            height: 18px;
            width: 18px;
            font-size: 18px;
        }

        .tag {
            line-height: 1;
            width: 50%;
            font-size: 14px;

            label {
                color: #999;
                display: block;
                margin: 0 0 5px 0;
            }
        }
    }

}

@media screen and (max-width: $md) {

    .container-fluid {
        padding: 0 15px;
    }

    .di-step-wrapper {
        border-radius: 10px;
        padding: 20px;
        min-height: auto;

        .step-heading {
            font-size: 1.2rem;
            margin: 0 0 20px 0;
            align-items: flex-start;
        }

        .blank-label {
            display: none;
        }
    }

    .di-selected-vehicle {
        height: auto;

        &.result {
            padding: 20px;
            border-radius: 10px;
        }
    }

    .di-steps-wrapper {
        margin: 15px 0 0 0;

        .steps-row {
            flex-direction: column-reverse;
        }
    }

    .toast-container {
        .toast {
            right: 20px;
            bottom: 82px;
        }
    }

    .filters-column {
        width: 100% !important;
    }

    .company_sub_row {
        flex-direction: column;
    }

    .mat-mdc-dialog-container{
        min-width:100% !important;
    }

    .custom-table {
        background: transparent;
        box-shadow: none !important;

        thead {
            display: none;
        }

        tbody {
            display: block !important;
            width: 100% !important;

            tr {
                display: block;
                height: auto !important;
                margin-bottom: 20px;
                background: #ffffff;
                box-shadow: 0px 0px 12px rgba($black, 0.1) !important;
                border-radius: 6px !important;

                td {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    word-break: break-word;
                    min-width: auto;

                    &:before {
                        position: relative;
                        min-width: 45%;
                        word-break: break-word;
                        padding-right: 10px;
                        white-space: nowrap;
                        content: attr(data-title);
                        font-weight: 600;
                    }

                    &:last-child {
                        border-bottom: 0;
                    }

                    // .material-symbols-outlined{
                    //   height:18px;
                    //   width:18px;
                    //   font-size:18px;
                    // }
                    .mat-chip-list,
                    .mat-chip-set,
                    .product-details,
                    .description {
                        max-width: 50vw;
                    }
                }
            }
        }
    }

}